<template>
    <v-container fluid class="pa-0">
        <v-dialog v-model="dialog" max-width="1200" persistent scrollable>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="editMode && !templateMode"
                    rounded
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="edit"
                    :disabled="
                        bookingOrder.orderStatus !== 'draft' &&
                            (!me.isTenantUser || bookingOrder.orderStatus === 'confirmed')
                    "
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">edit</v-icon>
                        </template>
                        <span>{{ $t("bookings.edit_booking") }}</span>
                    </v-tooltip>
                </v-btn>
                <v-btn
                    v-else-if="templateMode && !editMode && !mirrorMode"
                    rounded
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="create()"
                >
                    {{ $t("bookings.new_template") }}
                </v-btn>
                <v-btn
                    v-else-if="templateMode && mirrorMode"
                    plain
                    class="pa-0 ml-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="createTemplateFromOrder()"
                >
                    <v-icon left>file_copy</v-icon>
                    {{ $t("templates.create_new_template") }}
                </v-btn>
                <div v-else-if="editMode && templateMode"></div>
                <v-btn v-else rounded color="primary" class="" v-bind="attrs" v-on="on" @click="create()">
                    {{ $t("bookings.new_booking") }}
                </v-btn>
            </template>
            <!-- content -->
            <v-card flat>
                <v-card-title class="primary white--text pb-0" style="height:70px">
                    <v-row dense style="height:50px">
                        <v-col cols="auto" class="pb-0">
                            {{
                                editMode
                                    ? templateMode
                                        ? $t("bookings.edit_template")
                                        : $t("bookings.edit_booking")
                                    : templateMode
                                    ? $t("bookings.new_template")
                                    : $t("bookings.new_booking")
                            }}
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-col auto class="pb-0" v-if="!templateMode && !editMode">
                            <v-autocomplete
                                v-model="selectTemplate"
                                :loading="$apollo.queries.bookingOrderTemplatesQx.loading"
                                :items="bookingOrderTemplates"
                                cache-items
                                item-text="node.name"
                                return-object
                                flat
                                color="white"
                                hide-no-data
                                label="Select template"
                                no-data-text="No Template"
                                outlined
                                dense
                                clearable
                                @click:clear="clearObjectPropertyValues(objBookingOrder)"
                                @focus="$apollo.queries.bookingOrderTemplatesQx.skip = false"
                                @change="editAndPopulateBookingTemplate"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-stepper v-model="step" elevation="0">
                        <v-stepper-header class="elevation-0 px-1">
                            <v-stepper-step :complete="step > 1" step="1" editable>
                                Order Identification
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" step="2" editable>
                                Shipping Details
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 3" step="3" editable>
                                Product Specification
                            </v-stepper-step>
                            <v-divider
                                v-if="
                                    me.isTenantUser &&
                                        editMode &&
                                        !templateMode &&
                                        objBookingOrder.orderStatus !== 'draft'
                                "
                            ></v-divider>
                            <v-stepper-step
                                :complete="step > 4"
                                step="4"
                                v-if="
                                    me.isTenantUser &&
                                        editMode &&
                                        !templateMode &&
                                        objBookingOrder.orderStatus !== 'draft'
                                "
                                editable
                            >
                                Confirm Order
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content :step="1" class="pt-1">
                                <v-card>
                                    <v-card-text class="px-0">
                                        <v-form v-model="stepOneIsValid" ref="stepOneForm">
                                            <v-row dense>
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Order Identification
                                                    </div>
                                                </v-col>
                                                <!-- <v-col cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Customer Order No.
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Please enter the customer order number
                                                                    <br />
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="objBookingOrder.customerOrderNumber"
                                                        outlined
                                                        placeholder="Customer Order No."
                                                        dense
                                                        :rules="[rules.required]"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col> -->
                                                <v-col v-if="me.isTenantUser" cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Customer
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Please select the customer for this booking order
                                                                    <br />
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.customerId"
                                                        :items="customers"
                                                        cache-items
                                                        item-text="Name"
                                                        item-value="ConsigneeID"
                                                        flat
                                                        hide-no-data
                                                        :rules="[rules.required]"
                                                        :loading="$apollo.queries.customers.loading"
                                                        placeholder="Select Customer"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col v-else cols="6" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Forwarder
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Please select the forwarder for this booking order
                                                                    <br />
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.tenantId"
                                                        :loading="$apollo.queries.tenantPartnerUsers.loading"
                                                        :items="tenantList"
                                                        cache-items
                                                        item-text="tenant.name"
                                                        item-value="tenant.id"
                                                        flat
                                                        hide-no-data
                                                        :rules="[rules.required]"
                                                        placeholder="Select Forwarder"
                                                        auto-select-first
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Parties
                                                    </div>
                                                </v-col>

                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Exporter
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.exporterId"
                                                        :items="exporters"
                                                        :disabled="isEmpty(objBookingOrder.customerId)"
                                                        :loading="$apollo.queries.exporters.loading"
                                                        item-text="ShipperDescription"
                                                        item-value="ShipperCode"
                                                        flat
                                                        placeholder="Select Exporter"
                                                        outlined
                                                        hide-no-data
                                                        dense
                                                        :rules="[rules.required]"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Receiver
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.receiverId"
                                                        :items="receivers"
                                                        :disabled="isEmpty(objBookingOrder.customerId)"
                                                        :loading="$apollo.queries.receivers.loading"
                                                        search-input.sync
                                                        item-text="Name"
                                                        item-value="ReceiverID"
                                                        flat
                                                        placeholder="Select Receiver"
                                                        outlined
                                                        dense
                                                        :rules="[rules.required]"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Agent code
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.agentCodeId"
                                                        :items="agentCodes"
                                                        :disabled="isEmpty(objBookingOrder.receiverId)"
                                                        :loading="$apollo.queries.agentCodes.loading"
                                                        cache-items
                                                        item-text="AgentDescription"
                                                        item-value="AgentID"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Agent Code"
                                                        outlined
                                                        :rules="[rules.required]"
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Contract Details
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Contract Number
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Please enter the contract number
                                                                    <br />
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="objBookingOrder.contractNumber"
                                                        outlined
                                                        placeholder="Contract Number"
                                                        dense
                                                        :rules="[rules.required]"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Inco Term
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.incoTerm"
                                                        :items="terms"
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        hide-details
                                                        placeholder="Select Inco Terms"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Freight Pay Location</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.freightPayLocation"
                                                        :items="payMode"
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Select Mode Of Payment"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-spacer></v-spacer>
                                        <v-btn text rounded @click="cancel()" class="ml-2">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" rounded @click="step = 2">
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content :step="2" class="pt-1">
                                <v-card>
                                    <v-card-text class="px-0">
                                        <v-form v-model="stepTwoIsValid" ref="stepTwoForm">
                                            <v-row dense>
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Shipping details
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Vessel Name
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.vesselNameId"
                                                        :items="vesselBookings"
                                                        :loading="$apollo.queries.vesselBookings.loading"
                                                        :rules="[rules.required]"
                                                        cache-items
                                                        item-text="VesselDescription"
                                                        item-value="VesselCode"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Select Vessel"
                                                        outlined
                                                        dense
                                                        clearable
                                                        @change="onChangeVesselBookingId"
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Voyage No.
                                                            </span>
                                                        </v-col>
                                                        <!--  <v-col
                                                            cols="auto"
                                                            class="red--text"
                                                        >
                                                            *
                                                        </v-col> -->
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Please enter the voyage number
                                                                    <br />
                                                                    as per the shipping line's instructions.
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <!-- <v-text-field
                                                        v-model="
                                                            objBookingOrder.voyageNo
                                                        "
                                                        placeholder="Voyage No."
                                                        outlined
                                                        dense
                                                        :disabled="true"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-text-field> -->
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.voyageNoId"
                                                        :items="vesselBookingPorts"
                                                        :loading="$apollo.queries.vesselBookingPorts.loading"
                                                        item-text="ShippingNo"
                                                        item-value="ShippingNo"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Voyage"
                                                        outlined
                                                        dense
                                                        clearable
                                                        :disabled="!objBookingOrder.vesselNameId"
                                                        @change="onChangeVoyageNumber"
                                                    >
                                                        <!-- <template v-slot:item="data">
                                                            <v-row class="ma-0 body-2" dense>
                                                                <v-col
                                                                    cols="8"
                                                                    class="text-truncate pl-0 ml-0"
                                                                >
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-text="data.item.PortOfLoadingDescription"></v-list-item-title>
                                                                    <v-list-item-subtitle>ETD: {{ formatDateTime(data.item.ETD) || 'No ETD'}}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <span
                                                                        class="grey--text mr-0 float-right"
                                                                        ><i>{{ data.item.VoyageNumber || 'No Voyage' }}</i></span
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </template> -->
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Shipping Line
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    <!-- shipping company or shipping carrier, -->
                                                                    Please enter the shipping line <br />
                                                                    company name or carrier name
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>

                                                    <v-autocomplete
                                                        v-model="objBookingOrder.shippingLineId"
                                                        :items="shippingLines"
                                                        :loading="$apollo.queries.shippingLines.loading"
                                                        cache-items
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Select Shipping Line"
                                                        outlined
                                                        dense
                                                        :rules="[rules.required]"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <!-- <v-col cols="12" class="">
                                                    <div
                                                        class="title primary--text"
                                                    >
                                                        Destination & Freight
                                                    </div>
                                                </v-col> -->
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                POL
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <!-- <v-text-field
                                                        v-model="
                                                            objBookingOrder.finalDestination
                                                        "
                                                        placeholder="Amsterdam, Netherlands"
                                                        outlined
                                                        dense
                                                    ></v-text-field> -->
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.vesselBookingPolId"
                                                        :items="vesselBookingPorts"
                                                        :loading="$apollo.queries.vesselBookingPorts.loading"
                                                        item-text="PortOfLoadingDescription"
                                                        item-value="VesselBookingPOLID"
                                                        flat
                                                        hide-no-data
                                                        placeholder="POL"
                                                        outlined
                                                        dense
                                                        clearable
                                                        :disabled="!objBookingOrder.voyageNoId"
                                                        @change="onChangeVesselBookingPolId"
                                                    >
                                                        <template v-slot:item="data">
                                                            <v-row class="ma-0 body-2" dense>
                                                                <v-col cols="8" class="text-truncate pl-0 ml-0">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title
                                                                            v-text="data.item.PortOfLoadingDescription"
                                                                        ></v-list-item-title>
                                                                        <v-list-item-subtitle
                                                                            >ETD:
                                                                            {{
                                                                                qxFormatDateTime(data.item.ETD) ||
                                                                                    "No ETD"
                                                                            }}</v-list-item-subtitle
                                                                        >
                                                                    </v-list-item-content>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <span class="grey--text mr-0 float-right"
                                                                        ><i>{{
                                                                            data.item.ShippingNo || "No Voyage"
                                                                        }}</i></span
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                POD
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.vesselBookingPodId"
                                                        :items="vesselBookingPorts"
                                                        :loading="$apollo.queries.vesselBookingPorts.loading"
                                                        item-text="PortOfDischargeDescription"
                                                        item-value="VesselBookingPODID"
                                                        flat
                                                        hide-no-data
                                                        placeholder="POD"
                                                        outlined
                                                        dense
                                                        clearable
                                                        :disabled="!objBookingOrder.voyageNoId"
                                                        @change="onChangeVesselBookingPodId"
                                                    >
                                                        <template v-slot:item="data">
                                                            <v-row class="ma-0 body-2" dense>
                                                                <v-col cols="8" class="text-truncate pl-0 ml-0">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title
                                                                            v-text="
                                                                                data.item.PortOfDischargeDescription
                                                                            "
                                                                        ></v-list-item-title>
                                                                        <v-list-item-subtitle
                                                                            >ETA:
                                                                            {{
                                                                                qxFormatDateTime(data.item.ETA) ||
                                                                                    "No ETA"
                                                                            }}</v-list-item-subtitle
                                                                        >
                                                                    </v-list-item-content>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <span class="grey--text mr-0 float-right"
                                                                        ><i>{{
                                                                            data.item.ShippingNo || "No Voyage"
                                                                        }}</i></span
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Final Destination
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <!-- <v-text-field
                                                        v-model="
                                                            objBookingOrder.finalDestination
                                                        "
                                                        placeholder="Final Destination"
                                                        outlined
                                                        dense
                                                        clearable
                                                        disabled
                                                    ></v-text-field> -->
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.finalDestinationId"
                                                        :items="portDestinations"
                                                        :loading="$apollo.queries.portDestinations.loading"
                                                        item-text="DestinationDescription"
                                                        item-value="DestinationCode"
                                                        flat
                                                        placeholder="Final Destination"
                                                        :no-data-text="$t('loading.no_data')"
                                                        outlined
                                                        dense
                                                        clearable
                                                        :disabled="!objBookingOrder.podId"
                                                        @change="onChangeFinalDestination"
                                                    >
                                                        <!-- <template v-slot:item="data">
                                                            <v-row class="ma-0 body-2" dense>
                                                                <v-col
                                                                    cols="8"
                                                                    class="text-truncate pl-0 ml-0"
                                                                >
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-text="data.item.PortOfDischargeDescription"></v-list-item-title>
                                                                    <v-list-item-subtitle>ETA: {{ formatDateTime(data.item.ETA) || 'No ETA' }}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <span
                                                                        class="grey--text mr-0 float-right"
                                                                        ><i>{{ data.item.VoyageNumber || 'No Voyage' }}</i></span
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </template> -->
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                ETD
                                                            </span>
                                                        </v-col>
                                                        <!--  <v-col
                                                            cols="auto"
                                                            class="red--text"
                                                        >
                                                            *
                                                        </v-col> -->
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    POL estimated date of depature.
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="objBookingOrder.etd"
                                                        :icon="'calendar_month'"
                                                        :outlined="true"
                                                        :dense="true"
                                                        placeholder="ETD"
                                                        :defaultTime="'00:00'"
                                                        :disabled="true"
                                                        :filled="templateMode"
                                                    >
                                                    </DateTimeComponent>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                ETA
                                                            </span>
                                                        </v-col>
                                                        <!--  <v-col
                                                            cols="auto"
                                                            class="red--text"
                                                        >
                                                            *
                                                        </v-col> -->
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    POD estimated date of arrival.
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="objBookingOrder.eta"
                                                        :icon="'calendar_month'"
                                                        :outlined="true"
                                                        :dense="true"
                                                        placeholder="ETA"
                                                        :defaultTime="'00:00'"
                                                        :disabled="true"
                                                        :filled="templateMode"
                                                    >
                                                    </DateTimeComponent>
                                                    <!-- <v-text-field
                                                        v-model="
                                                            objBookingOrder.eta
                                                        "
                                                        placeholder="ETA"
                                                        outlined
                                                        dense
                                                        :disabled="true"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-text-field> -->
                                                </v-col>
                                                <!--<v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Imo Number
                                                            </span>
                                                        </v-col>
                                                    </v-row>

                                                    <v-text-field
                                                        v-model="objBookingOrder.imo"
                                                        placeholder="Imo"
                                                        outlined
                                                        dense
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>-->
                                                <!-- <v-col cols="12" class="d-flex flex-row">
                                                    <div class="title primary--text">
                                                        Scheduling & Timing
                                                    </div>
                                                    <div cols="auto" class="red--text mt-1 ml-2">
                                                        <v-tooltip right class="">
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    small
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :style="styleBorder"
                                                                    color="primary"
                                                                >
                                                                    priority_high
                                                                </v-icon>
                                                            </template>
                                                            <span>
                                                                Here you can enter when your stack <br />
                                                                opens and closes for better data result.
                                                            </span>
                                                        </v-tooltip>
                                                    </div>
                                                </v-col> -->
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Stack Open
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="objBookingOrder.stackOpen"
                                                        :icon="'calendar_month'"
                                                        :outlined="true"
                                                        :dense="true"
                                                        placeholder="Stack Open"
                                                        :defaultTime="'00:00'"
                                                        :disabled="true"
                                                        :filled="templateMode"
                                                    >
                                                    </DateTimeComponent>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Stack Close
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <DateTimeComponent
                                                        v-model="objBookingOrder.stackClose"
                                                        :icon="'calendar_month'"
                                                        :outlined="true"
                                                        :dense="true"
                                                        :defaultTime="'00:00'"
                                                        placeholder="Stack Close"
                                                        :disabled="true"
                                                        :filled="templateMode"
                                                    >
                                                    </DateTimeComponent>
                                                </v-col>
                                                <!-- stack status -->
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Stack Status</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.stackStatus"
                                                        :items="stackStatusList"
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        placeholder="Select Stack Status"
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                        :disabled="true"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Transport Type
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.transportType"
                                                        :items="transportTypeList"
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Select Transport Type"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Container Type
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.containerTypeId"
                                                        :items="containerTypes"
                                                        :loading="$apollo.queries.containerTypes.loading"
                                                        cache-items
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        hide-no-data
                                                        placeholder="Select Container Type"
                                                        :rules="[rules.required]"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Container Instructions
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-textarea
                                                        v-model="objBookingOrder.containerInstructions"
                                                        placeholder="Container Instructions"
                                                        outlined
                                                        dense
                                                        no-resize
                                                        height="80px"
                                                        hide-details
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn text rounded @click="step = 1" outlined>
                                            <v-icon small> arrow_back_ios </v-icon>{{ "Back" }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn rounded text @click="cancel()">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" rounded @click="step = 3">
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content :step="3" class="pt-1">
                                <v-card>
                                    <v-card-text class="px-0">
                                        <v-form v-model="stepThreeIsValid" ref="stepThreeForm">
                                            <v-row dense>
                                                <v-col cols="12">
                                                    <div class="title primary--text">
                                                        Product Specification
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Steri
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.steriId"
                                                        placeholder="Select Steri"
                                                        :items="steriList"
                                                        cache-items
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                    <!-- <v-autocomplete
                                                        v-model="
                                                            objBookingOrder.steriId
                                                        "
                                                        :items="
                                                            lookupsObj.targetMarket
                                                        "
                                                        @click="
                                                            lookupsObj.targetMarket
                                                                ? getLookups(
                                                                      'targetMarket'
                                                                  )
                                                                : null
                                                        "
                                                        :loading="
                                                            loading.targetMarket
                                                        "
                                                        cache-items
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        hide-no-data
                                                        hide-details
                                                        placeholder="Steri"
                                                        outlined
                                                        dense
                                                    ></v-autocomplete> -->
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Number Of Probes</span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text" v-if="showSteriProtocol">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="objBookingOrder.numberOfProbes"
                                                        placeholder="Number of Probes"
                                                        outlined
                                                        dense
                                                        :disabled="templateMode || !showSteriProtocol"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Steri Protocol
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text" v-if="showSteriProtocol">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.steriProtocolId"
                                                        :items="steriProtocols"
                                                        :loading="$apollo.queries.steriProtocols.loading"
                                                        cache-items
                                                        item-text="Description"
                                                        item-value="SteriProtocolID"
                                                        flat
                                                        hide-no-data
                                                        hide-details
                                                        placeholder="Select Steri Protocol"
                                                        outlined
                                                        dense
                                                        :disabled="!showSteriProtocol"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <!-- <v-col cols="12" class="d-flex flex-row">
                                                    <div class="title primary--text">
                                                        Specifications
                                                    </div>
                                                    <div cols="auto" class="red--text mt-1 ml-2">
                                                        <v-tooltip right class="">
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs
                                                                }"
                                                            >
                                                                <v-icon
                                                                    small
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :style="styleBorder"
                                                                    color="primary"
                                                                >
                                                                    priority_high
                                                                </v-icon>
                                                            </template>
                                                            <span>
                                                                Here you can enter the specifications
                                                                <br />
                                                                of the cargo for better data result.
                                                            </span>
                                                        </v-tooltip>
                                                    </div>
                                                </v-col> -->
                                                <v-col cols="3" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Commodity
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.commodityId"
                                                        placeholder="Ex. Apples"
                                                        :items="commodities"
                                                        :loading="$apollo.queries.commodities.loading"
                                                        cache-items
                                                        item-text="CommodityDescription"
                                                        item-value="CommodityCode"
                                                        :rules="[rules.required]"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                PPECB Temp Code
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.ppecbTempCodeId"
                                                        :items="tempCodes"
                                                        :loading="$apollo.queries.tempCodes.loading"
                                                        cache-items
                                                        item-text="Code"
                                                        item-value="Code"
                                                        flat
                                                        hide-no-data
                                                        hide-details
                                                        placeholder="Select PPECB Temp Code"
                                                        :rules="[rules.required]"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Vents
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.ventsId"
                                                        placeholder="Select Vent"
                                                        :items="vents"
                                                        :loading="$apollo.queries.vents.loading"
                                                        cache-items
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                
                                                <v-col cols="3" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Import Permit Number</span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text"> </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="objBookingOrder.importPermitNumber"
                                                        placeholder="Import Permit Number"
                                                        outlined
                                                        dense
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :rules="[]"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                
                                                
                                               <!--  <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Cargo & Weigh Details
                                                    </div>
                                                </v-col> -->
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Number Of Shipments</span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="objBookingOrder.numberOfShipments"
                                                        outlined
                                                        dense
                                                        placeholder="Number Of Shipments"
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :rules="[rules.required]"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>Approximate Gross Weight</span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model.number="objBookingOrder.approxGrossWeight"
                                                        placeholder="Approximate Gross Weight"
                                                        outlined
                                                        dense
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        :rules="[rules.required]"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col>
                                                <!-- ! weighBridge -->
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Weigh Bridge
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="auto" class="red--text">
                                                            *
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.weighBridgeId"
                                                        :items="weighBridges"
                                                        :loading="$apollo.queries.weighBridges.loading"
                                                        item-text="Description"
                                                        item-value="Code"
                                                        placeholder="Select Weigh Bridge"
                                                        :rules="[rules.required]"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn text rounded @click="step = 2" outlined>
                                            <v-icon small> arrow_back_ios </v-icon>{{ "Back" }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn rounded text @click="cancel()">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            v-if="
                                                !editMode ||
                                                    (editMode && !me.isTenantUser) ||
                                                    (editMode &&
                                                        me.isTenantUser &&
                                                        objBookingOrder.orderStatus === 'draft')
                                            "
                                            color="primary"
                                            @click="templateMode ? (templateDialog = true) : saveBooking()"
                                            rounded
                                            :loading="templateMode ? saveTemplateBtnLoading : saveBookingBtnLoading"
                                        >
                                            <!--   -->
                                            {{ templateMode ? "Save template" : "Save as draft" }}
                                        </v-btn>
                                        <v-btn
                                            v-if="editMode && templateMode"
                                            color="primary"
                                            @click="templateDialog = true"
                                            rounded
                                            :loading="saveBookingBtnLoading"
                                        >
                                            <!--   -->
                                            {{ "Save as draft" }}
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            rounded
                                            v-if="
                                                me.isTenantUser &&
                                                    editMode &&
                                                    !templateMode &&
                                                    objBookingOrder.orderStatus !== 'draft'
                                            "
                                            @click="step = 4"
                                        >
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content
                                :step="4"
                                class="pt-1"
                                v-if="
                                    me.isTenantUser &&
                                        editMode &&
                                        !templateMode &&
                                        objBookingOrder.orderStatus !== 'draft'
                                "
                            >
                                <v-card>
                                    <v-card-text class="px-0">
                                        <v-form v-model="stepFourIsValid" ref="stepFourForm">
                                            <v-row dense>
                                                <v-col cols="12" class="">
                                                    <div class="title primary--text">
                                                        Shipping Line Booking Details
                                                    </div>
                                                </v-col>
                                                <!-- <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Booking Reference
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-text-field
                                                        v-model="objBookingOrder.bookingReference"
                                                        outlined
                                                        dense
                                                        placeholder="Booking Reference"
                                                        clearable
                                                    ></v-text-field>
                                                </v-col> -->
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Booking status
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.shippingLineStatus"
                                                        placeholder="Select Booking Status"
                                                        :items="shippingLineStatusList"
                                                        cache-items
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Booking Comment
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.bookingComment"
                                                        :items="bookingComments"
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        hide-details
                                                        placeholder="Select Booking Comment"
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                    <!-- <v-textarea
                                                        v-model="
                                                            objBookingOrder.bookingComment
                                                        "
                                                        outlined
                                                        dense
                                                        no-resize
                                                        height="80px"
                                                        hide-details
                                                    ></v-textarea> -->
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Logistic Contact
                                                            </span>
                                                        </v-col>
                                                        <!-- <v-col
                                                            cols="auto"
                                                            class="red--text"
                                                        >
                                                            *
                                                        </v-col> -->
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.logisticContactId"
                                                        placeholder="Select Logco"
                                                        :items="logisticContacts"
                                                        :loading="$apollo.queries.logisticContacts.loading"
                                                        cache-items
                                                        item-text="Username"
                                                        item-value="User Id"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Shipped On Board Status
                                                            </span>
                                                        </v-col>
                                                        <!--  <v-col
                                                            cols="auto"
                                                            class="red--text"
                                                        >
                                                            *
                                                        </v-col> -->
                                                        <v-col cols="auto" class="red--text mt-n1">
                                                            <v-tooltip right class="">
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-icon
                                                                        small
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        :style="styleBorder"
                                                                        color="primary"
                                                                    >
                                                                        priority_high
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    <!-- shipping company or shipping carrier, -->
                                                                    Please enter the shipment <br />
                                                                    onboard status
                                                                </span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.shippedOnBoard"
                                                        placeholder="Select Shipped On Board Status"
                                                        :items="shippedStatus"
                                                        cache-items
                                                        item-text="text"
                                                        item-value="value"
                                                        flat
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                                <!-- <v-col cols="4" class="py-0">
                                                    <v-row dense>
                                                        <v-col cols="auto" class="text">
                                                            <span>
                                                                Empty Load Point
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-autocomplete
                                                        v-model="objBookingOrder.emptyLoadPointId"
                                                        :items="containerDepots"
                                                        :loading="$apollo.queries.containerDepots.loading"
                                                        item-text="Description"
                                                        item-value="Code"
                                                        flat
                                                        placeholder="Select Empty Load Point"
                                                        hide-no-data
                                                        outlined
                                                        dense
                                                        :disabled="templateMode"
                                                        :filled="templateMode"
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col> -->
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn text rounded @click="step = 3" outlined>
                                            <v-icon small> arrow_back_ios </v-icon>{{ "Back" }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn rounded text @click="cancel()">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="saveBooking"
                                            rounded
                                            :disabled="
                                                !stepOneIsValid &&
                                                    !stepTwoIsValid &&
                                                    !stepThreeIsValid &&
                                                    !stepFourIsValid
                                            "
                                            :loading="saveBookingBtnLoading"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="templateDialog" max-width="500px" persistent content-class="px-0">
            <v-card flat>
                <v-card-title class="primary white--text pb-0" style="height:70px">
                    {{ $t("bookings.template_title") }}
                </v-card-title>
                <v-card-text class="pt-4">
                    <v-form v-model="templateFormIsValid">
                        <v-row>
                            <v-col>
                                <div>
                                    <v-row dense>
                                        <v-col cols="auto" class="text">
                                            <span>
                                                Template name
                                            </span>
                                        </v-col>
                                        <v-col cols="auto" class="red--text">
                                            *
                                        </v-col>
                                    </v-row>
                                    <v-text-field
                                        v-model="template.name"
                                        append-icon="mdi-magnify"
                                        hide-details
                                        outlined
                                        dense
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </div>
                                <div class="mt-2">
                                    <v-row dense>
                                        <v-col cols="auto" class="text">
                                            <span>
                                                Template description
                                            </span>
                                        </v-col>
                                        <!-- <v-col
                                            cols="auto"
                                            class="red--text"
                                        >
                                            *
                                        </v-col> -->
                                    </v-row>
                                    <v-textarea v-model="template.description" outlined dense no-resize> </v-textarea>
                                </div>
                                <div>
                                    <v-row dense>
                                        <v-col cols="auto" class="text">
                                            <span>
                                                Lock template
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-switch v-model="template.lockTemplate" hide-details></v-switch>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="py-6">
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text rounded @click="closeTemplateDialog">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" rounded @click="saveTemplate" :loading="saveTemplateBtnLoading">
                        {{ editMode ? "Update" : "Create" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
/* import BookingOrderForm from "../forms/BookingOrderForm.vue"; */
//import TransportOrder from "../create/TransportOrder.vue";
import helper from "@/utils/helper.js";
import DateTimeComponent from "@/components/base/DateTimeComponent.vue";
import gql from "graphql-tag";
import moment from "moment";
import { BookingPermissions } from "@/core/enum";

export default {
    components: {
        /* BookingOrderForm, */
        // TransportOrder
        DateTimeComponent
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        templateMode: {
            type: Boolean,
            default: false
        },
        mirrorMode: {
            type: Boolean,
            default: false
        },
        bookingOrder: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        bookingOrderTemplatesQx: {
            query: gql`
                query bookingOrderTemplatesQx {
                    bookingOrderTemplatesQx {
                        edges {
                            node {
                                id
                                name
                                description
                                bookingJson
                                lockTemplate
                                tenant {
                                    id
                                }
                                tenantPartner {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {};
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.bookingOrderTemplateQx,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.bookingOrderTemplates = [];
                    this.bookingOrderTemplates = [...data.bookingOrderTemplatesQx.edges];
                }
            },
            skip: true
        },
        tenantPartners: {
            query: gql`
                query tenantPartners($hasPermission: String, $tenantId: ID) {
                    tenantPartners(hasPermission: $hasPermission, tenantId: $tenantId) {
                        edges {
                            node {
                                id
                                name
                                partnerType
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    hasPermission: BookingPermissions.VIEW_BOOKING_ORDER_QX,
                    tenantId: this.me?.tenant?.id
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.tenantPartners,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.tenantPartnerList = [];
                    this.tenantPartnerList = [...data.tenantPartners.edges];
                }
            },
            skip: true
        },
        tenantPartnerUsers: {
            query: gql`
                query tenantPartnerUsers($user: ID) {
                    tenantPartnerUsers(user: $user) {
                        edges {
                            node {
                                id
                                tenantPartner {
                                    id
                                    referenceNumber
                                    tenant {
                                        id
                                        name
                                        organisationPermissions
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    user: this?.me?.id
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.tenantPartnerUsers,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ loading, data }) {
                if (!loading) {
                    this.tenantList = [];
                    data.tenantPartnerUsers?.edges?.forEach(ele => {
                        if (
                            ele?.node?.tenantPartner?.tenant?.organisationPermissions.includes(
                                BookingPermissions.MANAGE_BOOKING_ORDER_QX
                            )
                        ) {
                            this.tenantList.push(ele.node.tenantPartner);
                        }
                    });
                    if (this.tenantList.length > 0) {
                        this.objBookingOrder.tenantId = this.tenantList[0].tenant.id;
                        if (this?.me?.isPartnerUser) {
                            this.objBookingOrder.customerId = this.tenantList[0].referenceNumber;
                        }
                    }
                }
            },
            skip: true
        },
        customers: {
            query: gql`
                query QxCustomers($consigneeId: String) {
                    qxCustomers(consigneeId: $consigneeId) {
                        ConsigneeID
                        Currency
                        DealTypeDescription
                        Name
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // consigneeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxCustomers,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return false; //this?.me?.isPartnerUser
            }
        },
        exporters: {
            query: gql`
                query QxExporters($consigneeId: String) {
                    qxExporters(consigneeId: $consigneeId) {
                        ConsigneeID
                        ConsigneeName
                        ShipperCode
                        ShipperDescription
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    consigneeId: this.objBookingOrder?.customerId
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxExporters,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.objBookingOrder?.customerId;
            }
        },
        receivers: {
            query: gql`
                query QxReceivers($consigneeId: String, $receiverId: String) {
                    qxReceivers(consigneeId: $consigneeId, receiverId: $receiverId) {
                        ConsigneeID
                        ConsigneeReceiverID
                        Name
                        ReceiverID
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    consigneeId: this.objBookingOrder?.customerId
                    // "receiverId": this.objBookingOrder?.receiverId
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxReceivers,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.objBookingOrder?.customerId;
            }
        },
        agentCodes: {
            query: gql`
                query QxAgentCodes($receiverId: String, $consigneeId: String) {
                    qxAgentCodes(receiverId: $receiverId, consigneeId: $consigneeId) {
                        AgentID
                        AgentCode
                        AgentDescription
                        ConsigneeID
                        ConsigneeReceiverID
                        ReceiverID
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    receiverId: this.objBookingOrder?.receiverId,
                    consigneeId: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxAgentCodes,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.objBookingOrder?.receiverId;
            }
        },
        commodities: {
            query: gql`
                query QxCommodities($commodityId: String) {
                    qxCommodities(commodityId: $commodityId) {
                        CommodityCode
                        CommodityDescription
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // commodityId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxCommodities,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        tempDevices: {
            query: gql`
                query QxTempDevices($tempDeviceId: String) {
                    qxTempDevices(tempDeviceId: $tempDeviceId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // tempDeviceId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTempDevices,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        tempCodes: {
            query: gql`
                query QxTempCodes($tempCodeId: String) {
                    qxTempCodes(tempCodeId: $tempCodeId) {
                        Code
                        Description
                        Temperature
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // tempCodeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTempCodes,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        containerTypes: {
            query: gql`
                query QxContainerTypes($containerTypeId: String) {
                    qxContainerTypes(containerTypeId: $containerTypeId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // containerTypeId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxContainerTypes,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        depots: {
            query: gql`
                query QxDepots($depotId: String) {
                    qxDepots(depotId: $depotId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // depotId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxDepots,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        // containerDepots: {
        //     query: gql`
        //         query QxConatinerDepots($code: String) {
        //             qxContainerDepots(code: $code) {
        //                 Code
        //                 Description
        //             }
        //         }
        //     `,
        //     variables() {
        //         // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
        //         return {
        //             // depotId: ""
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-first",
        //     nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
        //     update: data => data.qxContainerDepots,
        //     watchLoading(isLoading) {
        //         this.$emit("loading", isLoading);
        //     }
        // },
        logisticContacts: {
            query: gql`
                query QxLogisticContacts($userId: String) {
                    qxLogisticContacts(userId: $userId) {
                        FirstName
                        Surname
                        UserID
                        Username
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // userId: ""
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxLogisticContacts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        shippingLines: {
            query: gql`
                query QxShippingLines($shippingLineId: String) {
                    qxShippingLines(shippingLineId: $shippingLineId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "shippingLineId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxShippingLines,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        solasVerificationMethods: {
            query: gql`
                query QxSolasVerificationMethods($solasVerificationMethodId: String) {
                    qxSolasVerificationMethods(solasVerificationMethodId: $solasVerificationMethodId) {
                        Description
                        VerificationMethod
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "solasVerificationMethodId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxSolasVerificationMethods,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        steriProtocols: {
            query: gql`
                query QxSteriProtocols($steriProtocolId: String) {
                    qxSteriProtocols(steriProtocolId: $steriProtocolId) {
                        Description
                        SteriProtocolID
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "steriProtocolId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxSteriProtocols,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        transporterStatuses: {
            query: gql`
                query QxTransporterStatuses($transporterStatusId: Int) {
                    qxTransporterStatuses(transporterStatusId: $transporterStatusId) {
                        StatusID
                        Description
                        DualLoad
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "transporterStatusId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTransporterStatuses,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        transporters: {
            query: gql`
                query QxTransporters($transporterId: String) {
                    qxTransporters(transporterId: $transporterId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "transporterId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxTransporters,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        vents: {
            query: gql`
                query QxVents($ventId: String) {
                    qxVents(ventId: $ventId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "ventId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxVents,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        weighBridges: {
            query: gql`
                query QxWeighBridges($weighBridgeId: String) {
                    qxWeighBridges(weighBridgeId: $weighBridgeId) {
                        Code
                        Description
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    // "weighBridgeId": null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxWeighBridges,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        // ports: {
        //     query: gql`
        //         query QxPorts($portId: String) {
        //             qxPorts(portId: $portId) {
        //                 Code
        //                 Description
        //                 TargetCountry
        //                 TargetRegion
        //             }
        //         }
        //     `,
        //     variables() {
        //         // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
        //         return {
        //             // "portId": null
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-first",
        //     nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
        //     update: data => data.qxPorts,
        //     watchLoading(isLoading) {
        //         this.$emit("loading", isLoading);
        //     }
        // },
        // vessels: {
        //     query: gql`
        //         query QxVessels($vesselId: String) {
        //             qxVessels(vesselId: $vesselId) {
        //                 Code
        //                 Description
        //                 VesselType
        //             }
        //         }
        //     `,
        //     variables() {
        //         // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
        //         return {
        //             // "vesselId": null
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-first",
        //     nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
        //     update: data => data.qxVessels,
        //     watchLoading(isLoading) {
        //         this.$emit("loading", isLoading);
        //     }
        // },
        vesselBookings: {
            query: gql`
                query QxVesselBookings($vesselBookingId: Int) {
                    qxVesselBookings(vesselBookingId: $vesselBookingId) {
                        VesselBookingID
                        VesselCode
                        VesselDescription
                        VesselType
                        VesselSARSCode
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    vesselBookingId: null //this.objBookingOrder.vesselBookingId,
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxVesselBookings,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            }
        },
        vesselBookingPorts: {
            query: gql`
                query QxAvailableVesselBookings(
                    $vesselBookingId: Int
                    $vesselBookingPolId: Int
                    $vesselBookingPodId: Int
                    $vesselCode: String
                    $shippingNo: String
                    $etdFilter: String
                    $etaFilter: String
                ) {
                    qxAvailableVesselBookings(
                        vesselBookingId: $vesselBookingId
                        vesselBookingPolId: $vesselBookingPolId
                        vesselBookingPodId: $vesselBookingPodId
                        vesselCode: $vesselCode
                        shippingNo: $shippingNo
                        etdFilter: $etdFilter
                        etaFilter: $etaFilter
                    ) {
                        VesselBookingID
                        VesselBookingPODID
                        VesselBookingPOLID
                        VesselCode
                        VesselDescription
                        VoyageNumber
                        ShippingNo
                        VesselSARSCode
                        ETD
                        ETA
                        StackCloseDate
                        StackOpenDate
                        StackStatus
                        TradeType
                        PortOfDischargeCode
                        PortOfDischargeDescription
                        PortOfLoadingCode
                        PortOfLoadingDescription
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    vesselCode: this.objBookingOrder.vesselNameId,
                    vesselBookingId: this.objBookingOrder.vesselBookingId,
                    vesselBookingPolId: this.objBookingOrder.vesselBookingPolId,
                    vesselBookingPodId: this.objBookingOrder.vesselBookingPodId,
                    shippingNo: this.objBookingOrder.voyageNoId,
                    etdFilter: this.etdFilter,
                    etaFilter: this.etaFilter
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxAvailableVesselBookings,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.objBookingOrder?.vesselNameId;
            }
        },
        portDestinations: {
            query: gql`
                query QxPortDestinations(
                    $portCode: String
                    $destinationCode: String
                    $destinationTargetRegion: String
                    $destinationTargetCountry: String
                ) {
                    qxPortDestinations(
                        portCode: $portCode
                        destinationCode: $destinationCode
                        destinationTargetRegion: $destinationTargetRegion
                        destinationTargetCountry: $destinationTargetCountry
                    ) {
                        PortCode
                        DestinationCode
                        DestinationDescription
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return {
                    portCode: this.objBookingOrder.podId,
                    destinationCode: null, //this.objBookingOrder.finalDestinationId,
                    destinationTargetRegion: null,
                    destinationTargetCountry: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network", // this setting can avoid query again after fetchMore
            update: data => data.qxPortDestinations,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.objBookingOrder?.polId;
            }
        }
    },
    data: () => ({
        step: 1,
        selectTemplate: null,
        dialog: false,
        bookingOrderTemplates: [],
        objBookingOrder: {
            tenantId: null,
            // tenantPartnerId: null,
            trip: null,
            customerOrderNumber: null,
            customer: null,
            customerId: null,
            exporter: null,
            exporterId: null,
            receiver: null,
            receiverId: null,
            agentCode: null,
            agentCodeId: null,
            shippingLine: null,
            shippingLineId: null,
            shippingLineStatus: null,
            shippingLineComment: null,
            vesselNameId: null,
            vesselName: null,
            voyageNoId: null,
            voyageNo: null,
            pol: null,
            polId: null,
            pod: null,
            podId: null,
            stackOpen: null,
            stackClose: null,
            stackStatus: null,
            finalDestination: null,
            finalDestinationId: null,
            contractNumber: null,
            commodity: null,
            commodityId: null,
            ppecbTempCode: null,
            ppecbTempCodeId: null,
            containerType: null,
            containerTypeId: null,
            vents: null,
            ventsId: null,
            steri: null,
            steriId: null,
            steriProtocol: "",
            steriProtocolId: null,
            importPermitNumber: null,
            containerInstructions: null,
            numberOfShipments: 0,
            numberOfProbes: 0,
            approxGrossWeight: 0,
            transportType: null,
            incoTerm: null,
            freightPayLocation: null,
            orderBy: null,
            orderDate: null,
            // bookingReference: null,
            shippedOnBoard: null,
            // emptyLoadPoint: null,
            // emptyLoadPointId: null,
            weighBridge: null,
            weighBridgeId: null,
            logisticContact: null,
            logisticContactId: null,
            orderStatus: null,
            bookingComment: null,
            etd: null,
            eta: null,
            imo: null,
            vesselBookingId: null,
            vesselBookingPolId: null,
            vesselBookingPodId: null,
            shippingNo: null,
            sailingWeek: null,
            tradeType: null
        },
        transportOrder: {
            ctoNumber: "",
            containerNumber: "",
            trackerNumber: "",
            sealNumber: "",
            transportType: "",
            solasMethod: "",
            solasMethodId: "",
            tempRecorderType: "",
            tempRecorderTypeId: "",
            loadPoint1: "",
            loadPoint1Id: "",
            loadPoint2: "",
            loadPoint2Id: "",
            dualLoad: false,
            transportInstructions: "",
            gensetIsRequired: false,
            estimatedGrossWeight: ""
        },
        stepOneIsValid: true,
        stepTwoIsValid: true,
        stepThreeIsValid: true,
        stepFourIsValid: true,
        stackStatusList: [
            { text: "PROV", value: "prov" },
            { text: "FIRM", value: "firm" }
        ],
        transportTypeList: [
            { text: "Contour Logistics", value: "CON001" },
            { text: "Carrier", value: "CARRIER" },
            { text: "Own Transport", value: "OWN" }
        ],
        terms: [
            {
                value: "CIF",
                text: "CIF(Cost, Insurance and Freight)"
            },
            {
                value: "FOB",
                text: "FOB(Free On Board)"
            }
        ],
        payMode: [
            { text: "Prepaid", value: "Prepaid" },
            { text: "Collect", value: "Collect" },
            { text: "Else where", value: "ElseWhere" }
        ],
        rules: {
            required: v => !helper.isEmpty(v) || "This filed is required",
            minLength: len => v => (v || "").length >= len || `Invalid character length, required ${len}`,
            maxLength: len => v => (v || "").length <= len || "Too long"
        },
        orderStatus: [
            { text: "Draft", value: "draft" },
            { text: "Submit", value: "pending" }
        ],
        containerTypesData: [],
        bookingComments: [
            // { text: "Draft", value: "draft" },
            { text: "Booking Rejected", value: "booking_rejected" },
            { text: "Vessel Overbooked", value: "vessel_overbooked" }
        ],
        steriList: [
            { text: "STERI", value: "steri" },
            { text: "DIY STERI", value: "diysteri" },
            { text: "N/A", value: "n/a" }
        ],
        statusList: [
            { text: "Pending", value: "pending" },
            { text: "Approved", value: "approved" },
            { text: "Declined", value: "declined" }
        ],
        shippingLineStatusList: [
            { text: "Booked", value: "booked" },
            { text: "Await Ref. Number", value: "await_ref_number" },
            { text: "Confirmed", value: "confirmed" },
            { text: "Cancelled", value: "cancelled" },
            { text: "Change Request", value: "change_request" }
        ],
        shippedStatus: [{ text: "On board", value: "on board" }],
        template: {
            id: null,
            name: null,
            description: null,
            lockTemplate: false
        },
        templateDialog: false,
        tenantPartnerList: [],
        tenantList: [],
        templateFormIsValid: false,
        selectedTemplateId: null,
        saveBookingBtnLoading: false,
        saveTemplateBtnLoading: false,
        etdFilter: null,
        etaFilter: null
    }),
    computed: {
        /* formValidate() {
            return this.$refs.form.validate();
        }, */
        primaryTheme() {
            if (this.$vuetify.theme.themes.light.primary) {
                return this.$vuetify.theme.themes.light.primary;
            } else {
                return "#1976d2";
            }
        },
        styleBorder() {
            return `border: 1px solid ${this.primaryTheme}; border-radius: 10px;`;
        },
        me() {
            return helper.me();
        },
        showSteriProtocol() {
            return this.objBookingOrder?.steriId?.includes("steri");
        }
    },
    created() {
        this.formatDateTime = helper.formatDateTime;
        this.qxFormatDateTime = helper.qxFormatDateTime;
    },
    watch: {
        "objBookingOrder.customerId": {
            handler: function(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.objBookingOrder.exporter = null;
                    this.objBookingOrder.receiver = null;
                }
            },
            deep: true,
            immediate: true
        },
        "objBookingOrder.receiverId": {
            handler: function(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.objBookingOrder.agentCode = null;
                }
            },
            deep: true,
            immediate: true
        },
        dialog: function(val) {
            if (val) {
                // this.$apollo.queries.bookingOrderTemplatesQx.skip = false;
                let currentDate = new Date();
                if (!this.editMode) {
                    currentDate.setUTCDate(currentDate.getUTCDate() - 1); // Subtract one day
                    currentDate.setUTCHours(23, 50, 0, 0); // Set time to 23:50:00.000

                    // Format the date and time to the required format
                    this.etdFilter = currentDate.toISOString().replace(".000Z", ".000Z");
                } else {
                    currentDate.setUTCDate(currentDate.getUTCDate() - 30); // Subtract one day
                    currentDate.setUTCHours(23, 50, 0, 0); // Set time to 23:50:00.000

                    // Format the date and time to the required format
                    this.etdFilter = currentDate.toISOString().replace(".000Z", ".000Z");
                }

                if (this.me.isTenantUser) {
                    this.$apollo.queries.tenantPartnerUsers.skip = true;
                    this.$apollo.queries.tenantPartners.skip = false;
                    // this.$apollo.queries.bookingOrderTemplatesQx.skip = false;
                } else {
                    this.$apollo.queries.tenantPartnerUsers.skip = false;
                    this.$apollo.queries.tenantPartners.skip = true;
                    // this.$apollo.queries.bookingOrderTemplatesQx.skip = true;
                }
            } else {
                this.$apollo.queries.tenantPartnerUsers.skip = true;
                this.$apollo.queries.tenantPartners.skip = true;
                this.$apollo.queries.bookingOrderTemplatesQx.skip = true;
                this.etdFilter = null;
                this.etaFilter = null;
            }
        }
    },
    methods: {
        async editAndPopulateBookingTemplate(bookingTemplate) {
            if (bookingTemplate === null || bookingTemplate === undefined) return;
            let _json = JSON.parse(bookingTemplate.node.bookingJson);
            let keys = Object.keys(_json);

            this.objBookingOrder = { ..._json };
            this.template.id = bookingTemplate.node.id;
            this.template.name = bookingTemplate.node.name;
            this.template.description = bookingTemplate.node.description;
            this.template.lockTemplate = bookingTemplate.node.lockTemplate;
            // this.objBookingOrder["tenantPartnerId"] =
            //     bookingTemplate.node.tenantPartner.id;
            this.objBookingOrder["tenantId"] = bookingTemplate.node.tenant.id;
            this.objBookingOrder.voyageNoId = this.objBookingOrder.voyageNo;
            this.objBookingOrder.polId = this.objBookingOrder.polId;
            this.objBookingOrder.podId = this.objBookingOrder.podId;

            /*  keys.forEach(key => {
                if (_json[key] != "None") {
                    this.objBookingOrder[key] = _json[key];
                } else {
                    this.objBookingOrder[key] = null;
                }
            }); */

            this.dialog = true;
        },
        isEmpty(value) {
            if (value === null || value === undefined) {
                return true;
            } else if (typeof value === "string") {
                return value.trim() === "";
            } else if (Array.isArray(value)) {
                return value.length === 0;
            } else if (typeof value === "object") {
                return Object.keys(value).length === 0;
            } else {
                return false;
            }
        },
        create() {
            this.dialog = true;
            // this.stepOneForm.resetValidation();
        },
        closeTemplateDialog() {
            this.templateDialog = false;
            this.template = {
                name: null,
                description: null,
                lockTemplate: false
            };
        },
        createTemplateFromOrder() {
            let keysToIgnore = [
                "customerOrderNumber",
                "contractNumber",
                "etd",
                "eta",
                "imo",
                "stackOpen",
                "stackClose",
                "stackStatus",
                "containerInstructions",
                "numberOfShipments",
                "numberOfProbes",
                "approxGrossWeight",
                "orderBy",
                "orderDate",
                // "bookingReference",
                "shippedOnBoard",
                // "emptyLoadPoint",
                // "emptyLoadPointId",
                "logisticContact",
                "logisticContactId",
                "orderStatus",
                "bookingComment",
                "transportOrder",
                "finalDestination",
                "finalDestinationId",
                "importPermitNumber"
            ];
            this.objBookingOrder = { ...this.bookingOrder };
            this.objBookingOrder.voyageNoId = this.objBookingOrder.voyageNo;
            let order = { ...this.objBookingOrder };
            let keys = Object.keys(order);
            keys.forEach(key => {
                if (keysToIgnore.includes(key)) {
                    this.objBookingOrder[key] = null;
                }
            });
        },
        clearObjectPropertyValues(objectToClear) {
            Object.keys(objectToClear).forEach(key => {
                if (typeof objectToClear[key] === "string") objectToClear[key] = null;
                else if (typeof objectToClear[key] === "number") objectToClear[key] = 0;
                else if (typeof objectToClear[key] === "boolean") objectToClear[key] = false;
            });
        },
        saveTemplate() {
            this.saveTemplateBtnLoading = true;
            const jsonPayload = {
                customerId: this.objBookingOrder?.customerId || "None",
                customer:
                    this.customers.find(item => item.ConsigneeID === this.objBookingOrder?.customerId)?.Name || "None",
                customerReceiverId:
                    this.receivers.find(item => item.ReceiverID === this.objBookingOrder?.receiverId)
                        ?.ConsigneeReceiverID || "None",
                exporterId: this.objBookingOrder.exporterId || "None",
                exporter:
                    this.exporters.find(item => item.ShipperCode === this.objBookingOrder?.exporterId)
                        ?.ShipperDescription || "None",
                receiverId: this.objBookingOrder?.receiverId || "None",
                receiver:
                    this.receivers.find(item => item.ReceiverID === this.objBookingOrder?.receiverId)?.Name || "None",
                agentCodeId: this.objBookingOrder?.agentCodeId || "None",
                agentCode:
                    this.agentCodes.find(item => {
                        return item.AgentID === this.objBookingOrder.agentCodeId;
                    })?.AgentDescription || "None",
                shippingLineId: this.objBookingOrder.shippingLineId || "None",
                shippingLine:
                    this.shippingLines.find(item => item.Code === this.objBookingOrder.shippingLineId)?.Description ||
                    "None",
                vesselNameId: this.objBookingOrder.vesselNameId || "None",
                vesselName: this.objBookingOrder.vesselName,
                voyageNoId: this.objBookingOrder.voyageNoId || "",
                voyageNo: this.objBookingOrder.voyageNo || "",
                polId: this.objBookingOrder.polId || "None",
                pol: this.objBookingOrder.pol,
                podId: this.objBookingOrder.podId || "None",
                pod: this.objBookingOrder.pod,
                commodityId: this.objBookingOrder.commodityId || "None",
                commodity:
                    this.commodities.find(item => item.CommodityCode === this.objBookingOrder.commodityId)
                        ?.CommodityDescription || "None",
                ppecbTempCodeId: this.objBookingOrder.ppecbTempCodeId || "None",
                ppecbTempCode:
                    this.tempCodes.find(item => item.Code === this.objBookingOrder.ppecbTempCodeId)?.Description ||
                    "None",
                containerTypeId: this.objBookingOrder.containerTypeId || "None",
                containerType:
                    this.containerTypes.find(item => item.Code === this.objBookingOrder.containerTypeId)?.Description ||
                    "None",
                ventsId: this.objBookingOrder.ventsId || "None",
                vents: this.vents.find(item => item.Code === this.objBookingOrder.ventsId)?.Description || "None",
                steri: this.steriList.find(item => item.value === this.objBookingOrder.steriId)?.text || "None",
                steriId: this.objBookingOrder.steriId || "None",
                steriProtocol:
                    this.steriProtocols.find(item => item.SteriProtocolID === this.objBookingOrder.steriProtocolId)
                        ?.Description || "None",
                steriProtocolId: this.objBookingOrder.steriProtocolId || "None",
                weighBridgeId: this.objBookingOrder.weighBridgeId || "None",
                weighBridge:
                    this.weighBridges.find(item => item.Code === this.objBookingOrder.weighBridgeId)?.Description ||
                    "None",
                transportType: this.objBookingOrder.transportType || "None",
                incoTerm: this.objBookingOrder.incoTerm || "None",
                freightPayLocation: this.objBookingOrder.freightPayLocation || "None",
                etd: this.objBookingOrder.etd ? helper.qxConvertDateTimeToUTC(this.objBookingOrder.etd) : null,
                eta: this.objBookingOrder.eta ? helper.qxConvertDateTimeToUTC(this.objBookingOrder.eta) : null,
                /* imo: this.objBookingOrder.imo || "None", */
                vesselBookingId: this.objBookingOrder.vesselBookingId,
                vesselBookingPolId: this.objBookingOrder.vesselBookingPolId,
                vesselBookingPodId: this.objBookingOrder.vesselBookingPodId
                //shippingNo: this.objBookingOrder.shippingNo,
                // sailingWeek: this.objBookingOrder.sailingWeek,
                // tradeType: this.objBookingOrder.tradeType
            };

            //generate JSON
            let generatedJson = JSON.stringify(jsonPayload);

            let payload = {
                tenantId: this?.me?.isTenantUser ? this.me?.tenant?.id : this.objBookingOrder.tenantId,
                customerId: jsonPayload?.customerId,
                customer: jsonPayload?.customer,
                name: this.template.name,
                description: this.template.description,
                lockTemplate: this.template.lockTemplate,
                bookingJson: generatedJson
            };

            if (!this.editMode) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation createBookingOrderTemplateQx($input: CreateBookingOrderTemplateQxInput!) {
                                createBookingOrderTemplateQx(input: $input) {
                                    bookingOrderTemplateQx {
                                        id
                                        name
                                        description
                                        bookingJson
                                        lockTemplate
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: payload
                        }
                    })
                    .then(response => {
                        this.$emit(
                            "template-created",
                            response.data.createBookingOrderTemplateQx.bookingOrderTemplateQx
                        );

                        const payload = {
                            color: "primary",
                            message: "Save template successfully!"
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);

                        this.closeTemplateDialog();
                        this.dialog = false;
                    })
                    .catch(error => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        this.saveTemplateBtnLoading = false;
                    });
            } else {
                let updatePayload = {
                    templateId: this.template.id,
                    ...payload
                };

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation updateBookingOrderTemplateQx($input: UpdateBookingOrderTemplateQxInput!) {
                                updateBookingOrderTemplateQx(input: $input) {
                                    bookingOrderTemplateQx {
                                        id
                                        name
                                        description
                                        bookingJson
                                        lockTemplate
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: updatePayload
                        }
                    })
                    .then(response => {
                        this.$emit(
                            "template-created",
                            response.data.updateBookingOrderTemplateQx.bookingOrderTemplateQx
                        );
                        this.closeTemplateDialog();
                        this.dialog = false;
                    })
                    .catch(error => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        this.saveTemplateBtnLoading = false;
                    });
            }
        },
        saveBooking() {
            this.saveBookingBtnLoading = true;

            let payload = {
                tenantId: this?.me?.isTenantUser ? this.me?.tenant?.id : this.objBookingOrder.tenantId,
                // tenantPartnerId: this.objBookingOrder?.tenantPartnerId,
                tripId: "",
                customerOrderNumber: this.objBookingOrder?.customerOrderNumber || "",
                customerId: this.objBookingOrder?.customerId || "",
                customer:
                    this.customers.find(item => item.ConsigneeID === this.objBookingOrder?.customerId)?.Name || "",
                customerReceiverId:
                    this.receivers.find(item => item.ReceiverID === this.objBookingOrder?.receiverId)
                        ?.ConsigneeReceiverID || "None",
                exporterId: this.objBookingOrder.exporterId || "",
                exporter: this.objBookingOrder?.exporterId
                    ? this.exporters.find(item => item.ShipperCode === this.objBookingOrder?.exporterId)
                          ?.ShipperDescription
                    : "",
                receiverId: this.objBookingOrder?.receiverId || "",
                receiver: this.objBookingOrder?.receiverId
                    ? this.receivers.find(item => item.ReceiverID === this.objBookingOrder?.receiverId)?.Name
                    : "",
                agentCodeId: this.objBookingOrder?.agentCodeId || "",
                agentCode: this.objBookingOrder.agentCodeId
                    ? this.agentCodes.find(item => {
                          return item.AgentID === this.objBookingOrder.agentCodeId;
                      })?.AgentDescription
                    : "",
                shippingLineId: this.objBookingOrder.shippingLineId || "",
                shippingLine: this.objBookingOrder.shippingLineId
                    ? this.shippingLines.find(item => item.Code === this.objBookingOrder.shippingLineId)?.Description
                    : "",
                shippingLineStatus: this.objBookingOrder.shippingLineStatus || "",
                shippingLineComment: this.objBookingOrder.shippingLineComment || "",
                vesselNameId: this.objBookingOrder.vesselNameId || "",
                vesselName: this.objBookingOrder.vesselName,
                voyageNoId: this.objBookingOrder.voyageNoId || "",
                voyageNo: this.objBookingOrder.voyageNo || "",
                // emptyLoadPointId: this.objBookingOrder.emptyLoadPointId || "",
                // emptyLoadPoint:
                //     this.containerDepots.find(item => item.Code === this.objBookingOrder.emptyLoadPointId)
                //         ?.Description || "",
                finalDestinationId: this.objBookingOrder.finalDestinationId || "",
                finalDestination: this.objBookingOrder.finalDestination,
                polId: this.objBookingOrder.polId || "",
                pol: this.objBookingOrder.pol,
                podId: this.objBookingOrder.podId || "",
                pod: this.objBookingOrder.pod,
                stackOpen: this.objBookingOrder.stackOpen
                    ? helper.qxConvertDateTimeToUTC(this.objBookingOrder.stackOpen)
                    : null,
                stackOpenId: "",
                stackClose: this.objBookingOrder.stackClose
                    ? helper.qxConvertDateTimeToUTC(this.objBookingOrder.stackClose)
                    : null,
                stackCloseId: "",
                stackStatus: this.objBookingOrder.stackStatus,
                stackStatusId: "",
                contractNumber: this.objBookingOrder.contractNumber || "",
                commodity:
                    this.commodities.find(item => item.CommodityCode === this.objBookingOrder.commodityId)
                        ?.CommodityDescription || "",
                commodityId: this.objBookingOrder.commodityId || "",
                ppecbTempCodeId: this.objBookingOrder.ppecbTempCodeId || "",
                ppecbTempCode:
                    this.tempCodes.find(item => item.Code === this.objBookingOrder.ppecbTempCodeId)?.Description || "",
                containerTypeId: this.objBookingOrder.containerTypeId || "",
                containerType:
                    this.containerTypes.find(item => item.Code === this.objBookingOrder.containerTypeId)?.Description ||
                    "",
                ventsId: this.objBookingOrder.ventsId || "",
                vents: this.vents.find(item => item.Code === this.objBookingOrder.ventsId)?.Description || "",
                steri: this.steriList.find(item => item.value === this.objBookingOrder.steriId)?.text || "None",
                steriId: this.objBookingOrder.steriId || "None",
                steriProtocol:
                    this.steriProtocols.find(item => item.SteriProtocolID === this.objBookingOrder.steriProtocolId)
                        ?.Description || "",
                steriProtocolId: this.objBookingOrder.steriProtocolId,
                logisticContact:
                    this.logisticContacts.find(item => item.UserId === this.objBookingOrder.logisticContactId)
                        ?.Username || "",
                logisticContactId: this.objBookingOrder.logisticContactId,
                weighBridge:
                    this.weighBridges.find(item => item.Code === this.objBookingOrder.weighBridgeId)?.Description || "",
                weighBridgeId: this.objBookingOrder.weighBridgeId,
                importPermitNumber: this.objBookingOrder.importPermitNumber || "",
                numberOfProbes: this.objBookingOrder.numberOfProbes || 0,
                numberOfShipments: this.objBookingOrder.numberOfShipments || 0,
                containerInstructions: this.objBookingOrder.containerInstructions || "",
                approxGrossWeight: this.objBookingOrder.approxGrossWeight || 0,
                transportType: this.objBookingOrder.transportType || "",
                incoTerm: this.objBookingOrder.incoTerm || "",
                freightPayLocation: this.objBookingOrder.freightPayLocation || "",
                orderBy: this.objBookingOrder.orderBy || "",
                orderStatus: "draft",

                // bookingReference: this.objBookingOrder.bookingReference || "",
                // ctoNumber: this.objBookingOrder.ctoNumber || "",
                // bookingTransportOrderQxs: [],
                etd: this.objBookingOrder.etd ? helper.qxConvertDateTimeToUTC(this.objBookingOrder.etd) : null,
                eta: this.objBookingOrder.eta ? helper.qxConvertDateTimeToUTC(this.objBookingOrder.eta) : null,
                imo: this.objBookingOrder.imo || "",
                vesselBookingId: this.objBookingOrder.vesselBookingId,
                vesselBookingPolId: this.objBookingOrder.vesselBookingPolId,
                vesselBookingPodId: this.objBookingOrder.vesselBookingPodId,
                shippingNo: this.objBookingOrder.shippingNo,
                sailingWeek: this.objBookingOrder.sailingWeek,
                tradeType: this.objBookingOrder.tradeType,
                shippedOnBoard: this.objBookingOrder.shippedOnBoard
            };

            if (this.editMode) {
                //update booking order UpdateBookingOrderQx
                payload.bookingOrderQxId = this.objBookingOrder.id;
                payload.orderStatus = this.objBookingOrder.orderStatus;
                payload.orderDate = this.objBookingOrder.orderDate;

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation UpdateBookingOrderQx($input: UpdateBookingOrderQxInput!) {
                                updateBookingOrderQx(input: $input) {
                                    bookingOrderQx {
                                        id
                                        approxGrossWeight
                                        commodity
                                        containerInstructions
                                        containerType
                                        contractNumber
                                        customer
                                        customerOrderNumber
                                        exporter
                                        finalDestination
                                        freightPayLocation
                                        importPermitNumber
                                        incoTerm
                                        logisticContact
                                        numberOfProbes
                                        numberOfShipments
                                        orderBy
                                        orderDate
                                        orderStatus
                                        pod
                                        pol
                                        ppecbTempCode
                                        receiver
                                        shippedOnBoard
                                        shippingLine
                                        shippingLineComment
                                        shippingLineStatus
                                        stackClose
                                        stackOpen
                                        stackStatus
                                        steri
                                        steriProtocol
                                        transportType
                                        vents
                                        vesselName
                                        voyageNo
                                        weighBridge
                                        weighBridgeId
                                        eta
                                        etd
                                        vesselBookingId
                                        vesselBookingPolId
                                        vesselBookingPodId
                                        shippingNo
                                        sailingWeek
                                        tradeType
                                        trip {
                                            id
                                            referenceNumber
                                        }
                                        bookingTransportOrderQxSet {
                                            edges {
                                                node {
                                                    id
                                                    loadPoint1
                                                    loadPoint2
                                                    loadDate
                                                    containerNumber
                                                    ctoNumber
                                                    sealNumber
                                                    trackerNumber
                                                    plugInDepot
                                                    transportComment
                                                    depotDateIn
                                                    depotDateOut
                                                    transportInstruction
                                                    orderStatus
                                                    solasMethod
                                                    tempRecorderType
                                                    gensetIsRequired
                                                    numberOfPalletsPerShipment
                                                    bookingReference
                                                    exporterReferenceNumber
                                                    emptyLoadPoint
                                                    emptyLoadPointId
                                                }
                                            }
                                        }
                                        bookingOrderChangeRequestQxSet {
                                            edges {
                                                node {
                                                    id
                                                    content
                                                    reason
                                                    status
                                                    title
                                                    modifiedAt
                                                    modifiedBy {
                                                        id
                                                        firstName
                                                        lastName
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(response => {
                        this.$emit("update-booking", response.data.updateBookingOrderQx.bookingOrderQx);
                        this.clearObjectPropertyValues(this.objBookingOrder);
                        this.dialog = false;
                    })
                    .catch(error => {
                        console.log("error", error);
                        if (error.message.includes("Customer Order Number already exists")) {
                            const payload = {
                                color: "red",
                                message: this.$t("Customer Order Number already exists.")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        }
                    })
                    .finally(() => {
                        this.saveBookingBtnLoading = false;
                    });
            } else {
                //create booking order
                // payload.tenantId = this?.me?.isTenantUser ? this.me?.tenant?.id : this.objBookingOrder.tenantId;
                payload.orderDate = moment().toISOString();

                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation createBookingOrderQx($input: CreateBookingOrderQxInput!) {
                                createBookingOrderQx(input: $input) {
                                    bookingOrderQx {
                                        id
                                        approxGrossWeight
                                        commodity
                                        containerInstructions
                                        containerType
                                        contractNumber
                                        customer
                                        customerOrderNumber
                                        exporter
                                        finalDestination
                                        freightPayLocation
                                        importPermitNumber
                                        incoTerm
                                        logisticContact
                                        numberOfProbes
                                        numberOfShipments
                                        orderBy
                                        orderDate
                                        orderStatus
                                        pod
                                        pol
                                        ppecbTempCode
                                        receiver
                                        shippedOnBoard
                                        shippingLine
                                        shippingLineComment
                                        shippingLineStatus
                                        stackClose
                                        stackOpen
                                        stackStatus
                                        steriId
                                        steri
                                        steriProtocolId
                                        steriProtocol
                                        transportType
                                        vents
                                        vesselName
                                        voyageNo
                                        weighBridge
                                        weighBridgeId
                                        eta
                                        etd
                                        vesselBookingId
                                        vesselBookingPolId
                                        vesselBookingPodId
                                        shippingNo
                                        sailingWeek
                                        tradeType
                                        trip {
                                            id
                                            referenceNumber
                                        }
                                        bookingTransportOrderQxSet {
                                            edges {
                                                node {
                                                    id
                                                    loadPoint1
                                                    loadPoint2
                                                    loadDate
                                                    containerNumber
                                                    ctoNumber
                                                    sealNumber
                                                    trackerNumber
                                                    plugInDepot
                                                    transportComment
                                                    depotDateIn
                                                    depotDateOut
                                                    transportInstruction
                                                    orderStatus
                                                    solasMethod
                                                    tempRecorderType
                                                    gensetIsRequired
                                                    numberOfPalletsPerShipment
                                                    bookingReference
                                                    exporterReferenceNumber
                                                    emptyLoadPoint
                                                    emptyLoadPointId
                                                }
                                            }
                                        }
                                        bookingOrderChangeRequestQxSet {
                                            edges {
                                                node {
                                                    id
                                                    content
                                                    reason
                                                    status
                                                    title
                                                    modifiedAt
                                                    modifiedBy {
                                                        id
                                                        firstName
                                                        lastName
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(response => {
                        this.$emit("add-booking", response.data.createBookingOrderQx.bookingOrderQx);
                        this.clearObjectPropertyValues(this.objBookingOrder);
                        this.dialog = false;
                    })
                    .catch(error => {
                        console.log("error", error);
                        if (error.message.includes("Customer Order Number already exists")) {
                            const payload = {
                                color: "red",
                                message: this.$t("Customer Order Number already exists.")
                            };
                            this.$store.dispatch("snackbar/showMessage", payload);
                        }
                    })
                    .finally(() => {
                        this.saveBookingBtnLoading = false;
                    });
            }
        },
        edit() {
            this.objBookingOrder = { ...this.bookingOrder };
            this.objBookingOrder.voyageNoId = this.objBookingOrder.voyageNo;
            this.objBookingOrder.stackOpen = helper.qxFormatDateTime(this.bookingOrder.stackOpen);
            this.objBookingOrder.stackClose = helper.qxFormatDateTime(this.bookingOrder.stackClose);
            this.objBookingOrder.etd = helper.qxFormatDateTime(this.bookingOrder.etd);
            this.objBookingOrder.eta = helper.qxFormatDateTime(this.bookingOrder.eta);
            this.objBookingOrder.tenantId = this.bookingOrder.tenant.id;
            // this.objBookingOrder.tenantPartnerId = item.tenantPartner.id;
            /*   this.transportOrders = item.bookingTransportOrderQxSet.edges.map(
                edge => edge.node
            ); */
            this.dialog = true;
        },
        cancel() {
            if (this.editMode && !this.templateMode && this.objBookingOrder.orderStatus !== "draft")
                this.$refs?.stepFourForm.resetValidation();
            this.objBookingOrder = {};
            this.transportOrders = [];
            this.dialog = false;
            this.$refs.stepOneForm.resetValidation();
            this.$refs.stepTwoForm.resetValidation();
            this.$refs.stepThreeForm.resetValidation();

            this.step = 1;
        },
        getFormattedDateOneDayPrior() {
            // Get the current date and time in UTC
            let currentDate = new Date();
            currentDate.setUTCDate(currentDate.getUTCDate() - 1); // Subtract one day
            currentDate.setUTCHours(23, 50, 0, 0); // Set time to 23:50:00.000

            // Format the date and time to the required format
            let formattedDate = currentDate.toISOString().replace(".000Z", ".000Z");

            return formattedDate;
        },
        onChangeVesselBookingId(newVal) {
            let obj = [];
            obj = this.vesselBookings?.filter(item => item?.VesselCode === newVal);

            if (obj) {
                // this.objBookingOrder.vesselNameId = obj[0]?.VesselCode;
                this.objBookingOrder.vesselName = obj[0]?.VesselDescription;
                // this.objBookingOrder.voyageNoId = obj[0]?.VoyageNumber;
                // this.objBookingOrder.voyageNo = obj[0]?.VoyageNumber;
            }
            this.objBookingOrder.vesselBookingId = null;
            this.objBookingOrder.vesselBookingPolId = null;
            this.objBookingOrder.vesselBookingPodId = null;
            this.objBookingOrder.polId = null;
            this.objBookingOrder.pol = null;
            this.objBookingOrder.podId = null;
            this.objBookingOrder.pod = null;
            this.objBookingOrder.voyageNoId = null;
            this.objBookingOrder.voyageNo = null;
            this.objBookingOrder.etd = null;
            this.objBookingOrder.eta = null;
            this.objBookingOrder.shippingNo = null;
            this.objBookingOrder.sailingWeek = null;
            this.objBookingOrder.tradeType = null;
            this.objBookingOrder.stackOpen = null;
            this.objBookingOrder.stackClose = null;
            this.objBookingOrder.stackStatus = null;
            this.objBookingOrder.finalDestinationId = null;
            this.objBookingOrder.finalDestination = null;
        },
        onChangeVoyageNumber(newVal) {
            let obj = [];
            obj = this.vesselBookingPorts?.find(
                item => item?.VesselCode === this.objBookingOrder?.vesselNameId && item?.ShippingNo === newVal
            );

            if (obj) {
                // this.objBookingOrder.vesselNameId = obj[0]?.VesselCode;
                this.objBookingOrder.vesselBookingId = obj?.VesselBookingID;
                // this.objBookingOrder.voyageNoId = obj?.ShippingNo;
                this.objBookingOrder.voyageNo = obj?.ShippingNo;
                this.objBookingOrder.shippingNo = obj?.ShippingNo;
            }
            this.objBookingOrder.vesselBookingId = null;
            this.objBookingOrder.vesselBookingPolId = null;
            this.objBookingOrder.vesselBookingPodId = null;
            this.objBookingOrder.polId = null;
            this.objBookingOrder.pol = null;
            this.objBookingOrder.podId = null;
            this.objBookingOrder.pod = null;
            this.objBookingOrder.etd = null;
            this.objBookingOrder.eta = null;
            // this.objBookingOrder.shippingNo = null;
            this.objBookingOrder.sailingWeek = null;
            this.objBookingOrder.tradeType = null;
            this.objBookingOrder.stackOpen = null;
            this.objBookingOrder.stackClose = null;
            this.objBookingOrder.stackStatus = null;
            this.objBookingOrder.finalDestinationId = null;
            this.objBookingOrder.finalDestination = null;
        },
        onChangeVesselBookingPolId(newVal) {
            let obj = [];
            obj = this.vesselBookingPorts?.filter(
                item => item?.VesselCode === this.objBookingOrder?.vesselNameId && item?.VesselBookingPOLID === newVal
            );

            if (obj) {
                this.objBookingOrder.vesselBookingId = obj[0]?.VesselBookingID;
                this.objBookingOrder.voyageNo = obj[0]?.ShippingNo;
                this.objBookingOrder.polId = obj[0]?.PortOfLoadingCode;
                this.objBookingOrder.pol = obj[0]?.PortOfLoadingDescription;

                this.etaFilter = new Date(obj[0]?.ETD).toISOString().replace(".000Z", ".000Z");
            }
            this.objBookingOrder.vesselBookingPodId = null;
            this.objBookingOrder.podId = null;
            this.objBookingOrder.pod = null;
            // this.objBookingOrder.voyageNoId = null;
            // this.objBookingOrder.voyageNo = null;
            this.objBookingOrder.etd = null;
            this.objBookingOrder.eta = null;
            // this.objBookingOrder.shippingNo = null;
            this.objBookingOrder.sailingWeek = null;
            this.objBookingOrder.tradeType = null;
            this.objBookingOrder.stackOpen = null;
            this.objBookingOrder.stackClose = null;
            this.objBookingOrder.stackStatus = null;
            this.objBookingOrder.finalDestinationId = null;
            this.objBookingOrder.finalDestination = null;
        },
        onChangeVesselBookingPodId(newVal) {
            let obj = [];
            obj = this.vesselBookingPorts?.filter(
                item =>
                    item?.VesselBookingID === this.objBookingOrder?.vesselBookingId &&
                    item?.VesselBookingPOLID === this.objBookingOrder?.vesselBookingPolId &&
                    item?.VesselBookingPODID === newVal
            );

            if (obj) {
                this.objBookingOrder.podId = obj[0]?.PortOfDischargeCode;
                this.objBookingOrder.pod = obj[0]?.PortOfDischargeDescription;
                this.objBookingOrder.voyageNoId = obj[0]?.ShippingNo;
                this.objBookingOrder.voyageNo = obj[0]?.ShippingNo;
                this.objBookingOrder.etd = helper.qxFormatDateTime(obj[0]?.ETD);
                this.objBookingOrder.eta = helper.qxFormatDateTime(obj[0]?.ETA);
                // this.objBookingOrder.eta = helper.formatDateTime("2023-11-22T00:00:00.000Z")
                // this.objBookingOrder.shippingNo = obj[0]?.ShippingNo;
                this.objBookingOrder.sailingWeek = obj[0]?.SailingWeek;
                this.objBookingOrder.tradeType = obj[0]?.TradeType;
                this.objBookingOrder.stackOpen = helper.qxFormatDateTime(obj[0]?.StackOpenDate);
                this.objBookingOrder.stackClose = helper.qxFormatDateTime(obj[0]?.StackCloseDate);
                this.objBookingOrder.stackStatus = obj[0]?.StackStatus?.toLowerCase();
                // this.objBookingOrder.finalDestinationId = obj[0]?.PortOfDischargeCode;
                // this.objBookingOrder.finalDestination = obj[0]?.PortOfDischargeDescription;
            }
        },
        onChangeFinalDestination(newVal) {
            let obj = [];
            obj = this.portDestinations?.filter(
                item => item?.PortCode === this.objBookingOrder?.podId && item?.DestinationCode === newVal
            );

            if (obj) {
                this.objBookingOrder.finalDestinationId = obj[0]?.DestinationCode;
                this.objBookingOrder.finalDestination = obj[0]?.DestinationDescription;
            }
        }
    }
};
</script>
<style scoped>
.title {
    font-size: 16px;
    font-weight: 600;
}
.text {
    font-size: 13px;
    font-weight: 600;
}
</style>
